<template v-slot:main>
  <div class="flex">
    <div
      class="max-md:flex max-md:justify-center max-sm:flex max-sm:flex-col md:ml-auto md:text-right"
    >
      <div
        class="text-primary flex flex items-start text-[2.5rem] font-bold max-md:mr-10 sm:justify-center md:justify-end"
      >
        {{ $n(pricing.total_ttc, "decimal") }}
        <span class="text-[1.8rem]">€ <sup class="text-[12px]">TTC</sup></span>
      </div>
      <div class="max-sm:flex max-sm:flex-col">
        <div class="text-primary font-medium text-[0.75]">Détail du prix</div>
        <div class="text-neutral-lighter">
          <div>
            Prise en charge
            <span class="font-bold">
              {{ $n(pricing.pickup_fee, "currency") }}
            </span>
          </div>
          <div>
            Prix kilométrique
            <span class="font-bold">{{
              $n(pricing.km_price, "currency")
            }}</span>
          </div>
          <div>
            Commission Furnargos
            <span class="font-bold">{{
              $n(pricing.funargos_commission, "currency")
            }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { useConfigStore } from "~/store/config";
const { env } = useConfigStore();
const props = defineProps({
  pricing: {
    type: Object,
    required: true,
  },
});
</script>
