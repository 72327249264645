<template>
  <div
    class="border-primary-lightest max:md:justify-start w-full rounded-2xl border-2 p-7 transition-colors md:px-16 md:py-14"
  >
    <div
      class="text-primary mb-5 flex items-center justify-between uppercase"
      v-if="route?.departure_date"
    >
      <div>
        <icon icon="calendrier" class="mr-2 h-6 w-6"></icon>
        <span>
          {{
            formattedDate(route.departure_date, route.departure_time_of_day)
          }}</span
        >
      </div>

      <div class="flex items-center">
        <slot name="actions" />
      </div>
    </div>

    <div
      class="flex flex-col justify-between gap-3 border-b-2 border-dotted pb-5 lg:flex-row"
    >
      <div class="text-primary flex flex-col">
        <div class="flex min-h-[4rem]">
          <div class="mr-3 flex flex-col items-center py-2">
            <div class="bg-secondary h-4 w-4 rounded-full"></div>
            <div
              class="border-primary-lighter grow border-l-4 border-dotted border-opacity-60"
            ></div>
            <div class="bg-ternary h-4 w-4 rounded-full"></div>
          </div>
          <div
            class="flex flex-col justify-between whitespace-nowrap text-[1.5rem] font-semibold leading-[1.5rem]"
          >
            <div>
              <slot name="origin-name">{{ route?.origin_city?.name }}</slot>
            </div>

            <div>
              <slot name="destination-name">{{
                route?.destination_city?.name
              }}</slot>
            </div>
          </div>
        </div>
      </div>
      <div class="max-md:py-3">
        <slot name="main"></slot>
      </div>
    </div>
    <div class="pt-4">
      <slot name="footer" />
    </div>
  </div>
</template>
<script setup>
import { computed, defineProps } from "vue";
import InputSwitch from "primevue/inputswitch";

const dayjs = useDayjs();
const { $api } = useNuxtApp();
const checked = ref(false);

const props = defineProps({
  route: {
    type: Object,
    required: true,
  },
});

const computedDistance = computed(() => {
  return route.value.distance / 1000;
});

const formattedDate = (departureDate, departureTimeOfDay) => {
  departureTimeOfDay =
    departureTimeOfDay === "morning"
      ? "au matin (00h00 à 11h59)"
      : "après-midi (12h00 à 23h59)";

  return (
    "Départ le " +
    dayjs(departureDate).format("DD/MM/YYYY") +
    " " +
    departureTimeOfDay
  );
};
</script>
